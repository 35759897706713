import CountUp from 'react-countup';
import { Statistics } from '../../types/Stats';

export type Stat = {
  title: string;
  value: string;
  type?: Statistics;
};

interface StatBoxProps {
  stats: Stat[];
}

export default function StatBox({ stats }: StatBoxProps) {
  return (
    <div className="bg-primary transition-all w-full px-10 py-2 flex shadow-md flex-col gap-y-5 lg:flex-row items-center gap-x-10 md:justify-center rounded-md">
      {stats.map((stat, index) => (
        <div
          key={stat.title}
          className={`flex flex-col items-center justify-center text-center text-gray-100`}
        >
          <h3 className="mb-1">{stat.title}:</h3>
          <p className="text-lg font-medium lg:text-3xl w-48">
            {/*
               Hardcode for now to GBP, but would need to be internationalised in the future.
               Lots of questions of where this should come from - org config or from import itself?
            */}
            {stat.type === Statistics.MONETARY ? '£' : ''}
            <CountUp
              end={parseFloat(stat.value)}
              duration={0.5}
              separator=","
            />
          </p>
        </div>
      ))}
    </div>
  );
}
